import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class GlobalAlertService {
  private subject = new Subject();

  get messages(): Subject<any> {
    return this.subject;
  }

  setSuccess(message: string, timeout: number = 5000): void {
    this.messages.next({
      type: 'success',
      message,
      timeout,
    });
  }

  setWarning(message: string, timeout: number = 5000): void {
    this.messages.next({
      type: 'warn',
      message,
      timeout,
    });
  }

  setError(message: string, timeout: number = 5000): void {
    this.messages.next({
      type: 'error',
      message,
      timeout,
    });
  }
}
