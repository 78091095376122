import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { Registration } from '../model/registration.model';
import { ApiService } from './api.service';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RegistrationsService {
  readonly conflictError$ = new Subject<any[]>();
  readonly registrationTypes$: BehaviorSubject<any[]>;
  readonly registrationList$: Subject<Registration[]>;
  readonly filterRegistrationsBy$ = new BehaviorSubject<string>('ALL');
  readonly orgId$ = new BehaviorSubject<string>(null);

  get filteredRegistrationList$() {
    return combineLatest([this.registrationList$, this.filterRegistrationsBy$]).pipe(
      map(([list, filter]) => {
        switch (filter) {
          case 'DISPATCH':
            return list.filter(reg => (reg as any).purpose.includes(filter));
          case 'SETTLEMENT':
            return list.filter(reg => !(reg as any).purpose.includes('DISPATCH'));
          default:
            break;
        }
        return list;
      }),
    );
  }

  constructor(private apiService: ApiService) {
    this.registrationTypes$ = new BehaviorSubject<any[]>([]);
    this.registrationList$ = new Subject<Registration[]>();
    this.filterRegistrationsBy$ = new BehaviorSubject<string>('ALL');
  }

  async getRegistration(id: string) {
    let isFullReg = false;
    let registration: any = {};

    try {
      const response = await this.apiService.get(`registrations/${id}`);
      if (response.status === 409) {
        const { data } = response;

        this.conflictError$.next(data.conflicts);
        registration = data.registration;
      } else {
        this.conflictError$.next([]);
        registration = response;
      }

      isFullReg = 'dispatch_conf' in registration;
      return this.newRegistration(registration, isFullReg);
    } catch (err) {
      throw err;
    }
  }

  getRegistrationPoints(id: string) {
    return this.apiService.get(`registrations/${id}/points?monitors=AVAILABILITY&point_type=MeasuredPoint`);
  }

  async getRegistrations(orgId: string, orderBy?: string) {
    try {
      const registrations = await this.apiService.get(`registrations?org_id=${orgId}&order=${orderBy}`);
      this.orgId$.next(orgId);
      this.registrationList$.next(registrations);
    } catch (err) {
      console.log(err);
    }
  }

  async getMultipleRegistrations(regIds: any[]) {
    return this.apiService.post(`bulk-registrations`, { ids: regIds });
  }

  async refetchRegistrations() {
    if (this.orgId$.value) {
      await this.getRegistrations(this.orgId$.value, 'ASCEND');
    }
  }

  async getOrgFromAsset(assetId: string) {
    const { id } = await this.apiService.get(`flexible-assets/${assetId}/organization`);
    return id;
  }

  async getOrgFromSdp(sdpId: string) {
    const { id } = await this.apiService.get(`sdps/${sdpId}/organization`);
    return id;
  }

  async fetchRegistrationTypes() {
    try {
      const types = await this.apiService.get('registration-types');
      this.registrationTypes$.next(convertKeys.toCamel(types));
    } catch (err) {
      console.log('Could not load registration types.', err);
    }
  }

  async createRegistration(registration: Registration): Promise<Registration> {
    let dto = convertKeys.toSnake<any>(registration);
    dto.dispatch_conf = convertKeys.toSnake(dto.dispatch_conf);
    dto.market_conf = convertKeys.toSnake(dto.market_conf);

    this.removeEmpty(dto);
    dto.display_labels = registration.displayLabels;
    dto.descriptions = registration.descriptions;

    let createResponse;
    if (registration.fullReg) {
      createResponse = await this.apiService.post('registrations', dto);
    } else {
      createResponse = await this.apiService.post('registration-stubs', dto);
    }
    return this.newRegistration(createResponse, registration.fullReg);
  }

  async deleteRegistration(regId: string, isFullReg: boolean) {
    if (isFullReg) {
      return await this.apiService.delete(`registrations/${regId}`);
    } else {
      return await this.apiService.delete(`registration-stubs/${regId}`);
    }
  }

  async updateRegistration(registration: Registration) {
    const id = registration.id;
    let dto = convertKeys.toSnake<any>(registration);
    this.removeEmpty(dto);
    if (!dto.baseline_def_id) {
      dto.baseline_def_id = null;
    }
    dto.display_labels = registration.displayLabels;
    dto.descriptions = registration.descriptions;
    dto.energy_reduction_plan = registration.energyReductionPlan;
    let updatedRegistration;
    if (registration.fullReg) {
      updatedRegistration = await this.apiService.put(`registrations/${id}`, dto);
    } else {
      updatedRegistration = await this.apiService.put(`registration-stubs/${id}`, dto);
    }

    return this.newRegistration(updatedRegistration, registration.fullReg);
  }

  async fetchLoaStatus(sdpId: string): Promise<any> {
    try {
      let allServiceAccounts = await this.apiService.get(`service-accounts`);
      let foundServiceAccount;
      allServiceAccounts.map(serviceAccount => {
        if (
          serviceAccount.service_delivery_point_id === sdpId &&
          serviceAccount.status === 'ACTIVE' &&
          serviceAccount.loa_status
        ) {
          foundServiceAccount = serviceAccount;
        }
      });

      return foundServiceAccount?.loa_status || null;
    } catch (err) {
      console.log(`There was a problem fetching Loa Status with sdpId: ${sdpId}`);
    }
  }

  updateFilter(filterBy: string) {
    this.filterRegistrationsBy$.next(filterBy);
  }

  newRegistration(rawRegistration: any, fullReg: boolean) {
    const registration = convertKeys.toCamel<any>(rawRegistration);
    registration.alternateIds = registration.alternateIds || {};
    registration.displayLabels = rawRegistration.display_labels;
    registration.descriptions = rawRegistration.descriptions || {};
    registration.marketConf = registration.marketConf || {};
    if (fullReg) {
      return new Registration(
        registration.id,
        registration.displayLabel,
        registration.displayLabels,
        registration.productId,
        registration.defaultLocale,
        registration.timezone,
        registration.status,
        registration.baselineDefId,
        fullReg,
        registration.serviceDeliveryPointId,
        registration.alternateIds.registrationIdentifierName,
        registration.alternateIds.ecrmId,
        registration.description,
        registration.descriptions,
        registration.registrationSetId,
        registration.setId,
        registration.programId,
        registration.portfolioId,
        registration.flexibleAssetId,
        registration.effectiveDttm,
        registration.terminationDttm,
        registration.minimumValue,
        registration.maximumValue,
        registration.nominationOffset,
        registration.customerReadOnly,
        registration.eventAdvanceNotice,
        registration.minEventDuration,
        registration.maxEventDuration,
        registration.maxHoursPerDay,
        registration.lockedOut,
        registration.controlTimeoutOverride,
        registration.controlTimeOverride,
        registration.restoreTimeOverride,
        registration.initialNotifTimeOverride,
        registration.restoreNotifTimeOverride,
        registration.preauthorized,
        registration.controlType,
        registration.dispatchConf,
        registration.marketConf,
        registration.registrationType,
        registration.alternateIds.registrationExternalReferenceId,
        registration.dropToValue,
        registration.baselineValue,
        registration.loaStatus,
        registration.energyReductionPlan,
        registration.projectManagerName,
        registration.projectManagerId,
        registration.accountManagerName,
        registration.accountManagerId,
        registration.readyToTest,
      );
    } else {
      return new Registration(
        registration.id,
        registration.displayLabel,
        registration.displayLabels,
        registration.productId,
        registration.defaultLocale,
        registration.timezone,
        registration.status,
        registration.baselineDefId,
        fullReg,
        registration.serviceDeliveryPointId,
        registration.alternateIds.registrationIdentifierName,
        registration.alternateIds.ecrmId,
        registration.description,
        registration.descriptions,
        registration.registrationSetId,
      );
    }
  }

  removeEmpty = obj => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeEmpty(obj[key]);
      } else if (obj[key] === null || obj[key] === '') {
        delete obj[key];
      }
    });
  };
}
